footer {
    background-color: $beach;
    border-top: 1px solid $beach-dark;

    clear: both;
    color: $color-text-weak;

    margin-top: 2em;
    min-height: 160px;

    padding: 1em 0;

    .wrapper {
	display: flex;

	margin: 0 auto;
	max-width: 80em;

	div {
	    flex: 1 1 0;
	    padding: 0 2em;
	}
    }

    .copyright {
	clear: none;

	p {
	    padding-bottom: 1em;
	}
    }

}

.footer-logo {
    float: left;
    padding-right: 1em;
}

.social-links {
    clear: both;
    font-size: 1.4em;
    list-style-type: none;

    padding-top: .5em;

    li {
	display: inline-block;

	a {
            font-weight: normal;

	    i {
		border: 1px solid $button-border;
		border-radius: 100%;

		font-size: 1.25em;

		height: 2em;

		line-height: 2em;

		text-align: center;

		width: 2em;
            }
	}

	img {
	    max-width: 48px;
	}
    }

}

@media screen and (max-width: 790px) {

    footer {
	.wrapper {
	    display: block;
	    max-width: 100%;
	    padding: 0 1em;

	    div {
		display: block;
		padding: 0;
	    }
	}

	.footer-right {
	    clear: both;
	    text-align: left;
	}
    }

}

@media screen and (max-width: 470px) {

    .copyright {
	p {
	    font-size: 80%;
	}
    }

    footer {
	.wrapper {
	    padding: 0 16px 0 6px;
	}
    }

}
