.widgets {
    margin: 0 .5em;    

    @media screen and (max-width: 670px) {
	margin: 0;
    }
    
    iframe {
	border: none;
	margin: 0 .75em;
	padding: 0 0 1em 1em;

	@media screen and (max-width: 670px) {
	    max-width: 90%;
	}

	@media screen and (max-width: 480px) {
	    margin: 0;
	    padding: 0;
	    width: 100%;
	}
    }
}




