main {
    button {
	background: transparent;
	border: 2px $black solid;
	font-family: $header-font-stack;
	padding: .4em;
	text-align: center;

	&:hover {
	    background: $white;
	    border-color: $color-text-link-hover;
	    color: $color-text-link-hover;
	    cursor: pointer;
	}
    }

}

.callout-button {
    background: $orange;
    background-image: linear-gradient($orange-light, $orange);
    border: 3px solid $orange-dark;
    border-radius: 16px;
    box-shadow: inset 0 1px 0 0 $orange;

    color: $white;
    cursor: pointer;

    display: inline-block;

    font-family: $sans-serif-font-stack;
    font-size: .9em;
    font-weight: bold;

    line-height: 42px;
    margin-bottom: 8px;
    margin-right: 16px;
    padding: 6px 20px;

    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-shadow: 1px 1px 0 $black;

    text-transform: uppercase;
    
    img {
	padding-right: 4px;
	vertical-align: middle;
    }
}

.callout-button:hover {
    opacity: 0.6;
    text-shadow: none;
}

.callout-button:active {
    position: relative;
    top: 1px;
}

@media screen and (max-width: 480px) {
    .callout-button {
	max-width: 100%;
    }
}
