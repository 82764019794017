
// Greys
$white: #fff;
$black: #000;
$lightest-grey: #efefef;
$lighter-grey: #ddd;
$dark-grey: #bbb;
$darker-grey: #999;
$darkest-grey: #666;

// Oranges
$orange-light: #eca04b;
$orange: #e60;
$orange-dark: #f47d00;

// Sand
$beach-light: #f9efd4;
$beach: #f2e3ba;
$beach-dark: #e9d6a1;

// Assign colors

$color-background: $beach-light;
$color-background-shade: $lightest-grey;
$color-background-shadow: $lighter-grey;

$color-text: $black;
$color-text-weak: $darkest-grey;

$color-text-link: #354d90;
$color-text-link-hover: #33a2b6;

$color-text-header: $orange;
$hamburger-shadow: $darker-grey;

$menu-border-color: $darkest-grey;

$button-background: #eee;
$button-border: #aaa;
$button-text: #333;
