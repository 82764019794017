@mixin outline($color) {
  text-shadow: 2px 2px $color,
  -2px 2px $color,
  2px -2px $color,
  -2px -2px $color;
}

@mixin outline-thin($color) {
  text-shadow: 1px 1px $color,
  -1px 1px $color,
  1px -1px $color,
  -1px -1px $color;
}
