* {
    box-sizing: border-box;
    margin: 0;
    outline: 0;
    padding: 0;
}

body {
    background-color: $color-background;
    color: $color-text;

    font-family: $font-stack;
    font-size: 18px;
}

a {
    color: $color-text-link;
    font-weight: bold;
    text-decoration:  none;
    
    &:hover {
	color: $color-text-link-hover;
    }
}

h1 {
    @include outline($color-text);
    color: $color-text-header;
    font-family: $header-font-stack;
    font-size: 6em;
    line-height: .9em;
    @media screen and (max-width: 670px) {
	font-size: 3em;
    }
}

h2 {
    font-family: $subheader-font-stack;
    font-size: 2em;
    letter-spacing: 3px;
    padding: 1em;
    text-transform: uppercase;
    @media screen and (max-width: 670px) {
	font-size: 1.5em;
	letter-spacing: 1px;
	margin: .75em 0 0;
	padding: .6em .5em 0;	
    }
}

h3 {
    font-family: $subheader-font-stack;
    font-size: 1.625em;
    font-weight: normal;
    padding: 1em 0 0;
}

footer,
section{
    font-family: $sans-serif-font-stack;
}

main p {
    padding: 0 .5em 1em 1em;
    @media screen and (max-width: 670px) {
	padding: 1em 1em 0;
    }
	
}
.community {
    
    .container {
	display: flex;
	align-items: flex-start;

	.description, .widgets {
	    flex: 1;
	}

	p {
	    padding: 0 .5em 1em 1em;
	}
	
	@media screen and (max-width:670px) {
	    display: block;

	    p {
		padding: 1em 1em 0;
	    }
	}
    }

    

}
