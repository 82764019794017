.screenshots {
    ul {
	align-content: flex-start;
	display: flex;
	flex-flow: row wrap;
	margin: 0 0 0 3em;

	li {
	    flex: 1 1 content;
	    list-style: none;
	    margin: .2em;
	}
    }
}

.screenshot-item {
    border: 1px solid $color-text-link;
    max-width: 21em;

    &:hover {
	border: 5px solid $color-text-link;
	cursor: zoom-in;
    }
}

@media screen and (max-width: 1400px) {
    .screenshots {
	ul {
	    justify-content: center;
	}
    }
    
    .screenshot-item {
	max-width: 18em;
    }
}

@media screen and (max-width: 1200px) {
    .screenshot-item {
	max-width: 22em;
    }
}

@media screen and (max-width: 940px) {
  .screenshot-item {
      max-width: 18em;
  }
}


@media screen and (max-width: 790px) {

    .screenshot-item {
	max-width: 15em;
    }
}

@media screen and (max-width: 650px) {

    .screenshot-item {
	max-width: 100%;
    }

    .screenshots ul {
	margin-left: 0;

	li {
	    margin: 0 1em;
	}
    }
}
