// About the Game section styling

.about {

    // Paragraph layout rules
    p {
	padding: 0 .5em 1em 1em;
	// On phones/tablets
	@media screen and (max-width: 670px) {
	    padding: 1em 1em 0;
	}
    }

    h3 {
	padding: 0 0 .2em;
	// On phones/tablets
	@media screen and (max-width: 670px) {
	    padding: 1em .5em 0;
	}
    }
    
    // Main container
    .container {
	display: flex;
	// On desktops
	@media screen and (max-width: 1000px) {
	    display: block;
	}
    }
    
    // Link to another game by the designers
    .game-link {
	font-style: italic;
	text-decoration: none;
    }

}
