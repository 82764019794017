.subscribe {
    
    max-width: 550px;
    padding-bottom: 2em;
    
    .container {
	padding: 0;	
    }

    h2, h3 {
	padding-bottom: 1em;
    }    
    
}

#mc_embed_signup {
    padding: 0;
}

#mc_embed_signup_scroll {
    padding: 0;
}

#mc_embed_signup form {
    padding: 0;

    .callout-button {
	margin-top: 2em;
    }
}

