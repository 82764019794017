.hamburger {
    background-color: $beach;
    border: 1px solid $hamburger-shadow;
    border-radius: .5em;

    box-shadow: 3px 3px 3px $hamburger-shadow;

    margin: 3px;

    padding: 15px 12px 9px;
    position: absolute;

    right: 0;
    top: 0;

    z-index: 1;

    @media screen and (min-width: 791px) {
	display: none;
    }
    
}
