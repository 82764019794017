.site-nav {
    background: $color-background;
    
    font-size: 120%;
    font-weight: bold;

    position: relative;
    z-index: 1;
    
    a {
	text-decoration: none;
    }

    ul {
	list-style-type: none;
	padding-bottom: 2px;
	text-align: right;
    }

    li {
	display: inline-block;
	padding: 3px 1em 0;

	&:hover {
	    .arrow {
		transform: rotate(90deg);
		transition: transform .4s;
	    }
	}
    }

    .arrow {
	display: inline-block;
    }
}

@media screen and (max-width: 970px) {
    .site-nav {
	li {
	    padding: 3px 9px 0;
	}
    }
}

@media screen and (min-width: 791px) {
    .site-nav {
	ul {
	    background: $color-background;

	    ul {
		border: 2px solid $menu-border-color;
		border-top-width: 0;
		left: 0;
		margin-top: 2px;
		opacity: 0;
		padding: .5em 0;
		position: absolute;
		text-align: left;
		top: 100%;
		width: 100%;

		li {
		    display: block;
		}
	    }
	}


	li {
	    position: relative;

	    &:hover {
		ul {
		    display: block;
		    opacity: 1;
		    transition: opacity .4s;
		}
	    }
	}
    }
}

@media screen and (max-width: 790px) {

    .site-nav {

	a {
	    border-bottom: 1px solid $menu-border-color;
	    display: block;
	}

	.arrow {
	    display: none;
	}

	ul {
	    float: left;
	    max-height: 0;
	    overflow: hidden;
	    padding: 0 0 0 .5em;
	    position: static;
	    // transition: max-height .4s ease, padding .4s ease;

	    a {
		display: block;
	    }
	}

	li {
	    display: block;
	    font-size: 100%;
	    padding-top: 0;
	    text-align: left;
	    width: 100%;

	    li {
		display: none;
		padding-right: 0;
	    }

	    &:first-child {
		a {
		    margin-top: .2em;
		}
	    }
	}
    }

    .opened {

	.site-nav {
	    ul {
		max-height: 100%;
		overflow-x: visible;
		overflow-y: visible;
		width: 100%;
	    }

	    & > ul {
		padding: .5em;
	    }
	}
    }
}
