.team {
    ul {
	align-content: flex-start;
	display: flex;
	flex-flow: row wrap;
	justify-content: left;
	padding-bottom: 2em;

	@media screen and (max-width: 670px) {
	    padding-bottom: 0;
	}
	li {
	    align-items: flex-start;
	    display: flex;
	    flex: 1 1 24em;
	    flex-direction: row;
	    list-style: none;
	    margin: 1em;
	    max-width: 40em;

	    @media screen and (max-width: 670px) {
		flex-direction: column;
	    }
	}
    }
}

.team-profile {
    flex: 1 1 auto;
    min-width: 15em;

    a {
	font-weight: normal;
    }
}

.team-photo {
    flex: 0 0 auto;
    height: 128px;
    margin-right: 1em;
    width: 128px;
}
