.press-kit {
    
    dt {
	display: inline;
	font-weight: bold;

	::after {
	    content: ':';
	}
    }

    dd {
	display: inline;
	padding-left: .35em;
    }
    
    iframe {
	width: 480px;
    }
    
    ul {
	padding-left: 3em;
    }


    .container {
	display: flex;
    }

    .description,
    .widget {
	width: 50%;
    }

    .widget {
	text-align: right;
    }

}


@media screen and (max-width: 970px) {

    .press-kit {
	
	iframe {
	    height: 280px;
	    max-width: 100%;
	}
	
	ul {
	    padding-left: 1em;
	}
	
	.container {
	    display: block;
	}
	
	.widget {
	    padding-top: 2em;
	    text-align: left;
	}
	
	.description,
	.widget {
	    width: 100%;
	}
	
    }
}
