section {
    clear: both;
    margin: 0 auto;
    max-width: 80em;
    padding-bottom: 2em;

    ul,
    .container {
	padding-left: 3em;
	padding-right: 3em;
	@media screen and (max-width: 670px) {
	    padding-left: 0;
	    padding-right: 0;
	}
    }
}

// Centered text
.center {
    text-align: center;
}
