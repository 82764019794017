header {
    div {
	position: relative;
    }
    
    .banner {
	border-bottom: 2px solid $color-text-weak;
	border-top: 2px solid $color-text-weak;
	height: 75vh;
	min-height: 15em;
	object-fit: cover;
	opacity: .8;
	width: 100%;

	// Tablet
	@media screen and (max-width: 790px) {
	    height: 40vh;
	    min-height: 10em;
	}	
	
	// iPhone Portrait
	@media screen and (max-width: 480px) and (orientation: portrait)  {
	    min-height: 4em;
	    height: 30vh;
	}
	
    }
    
    .logo {
	bottom: 0;
	left: 0;
	margin: 0 auto;
	padding-bottom: 2em;
	position: absolute;
	right: 0;
	text-align: center;
	
	img {
	    margin: .1em 0 0 -100px;
	    width: 520px;

	    // Tablet
	    @media screen and (max-width: 790px) {
		margin: 0 0 0 1em;
		max-width: 80%;	
	    }

	    @media screen and (max-width: 790px) and (orientation: landscape) {
		max-width: 380px;
	    }
	    
	    @media screen and (max-width: 480px) {
		width: 320px;
	    }

	    @media screen and (max-width: 320px)  {
		width: 280px;
	    }
	}
	
	.site-title,
	.site-description {
	    position: absolute;
	    visibility: hidden;
	}
    }    
    
    .site-description {
	@include outline($color-text);
	color: $color-text-header;
	font-family: $header-font-stack;
	font-size: 3.5em;
	font-weight: bold;

	@media screen and (max-width: 790px) {
	    font-size: 1.5em;
	}
    }
}

.opened {

    .banner {
	display: none;
    }
}
