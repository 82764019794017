// Contact form styling
.contact-form {
    // Line up with top paragraph on main page
    clear: both;
    padding: 1em 0;

    label {
	display: block;
	padding: 0 0 .5em;
    }

    input,
    textarea {
	border-style: solid;
	border-width: 1px;
	box-sizing: border-box;

	font-family: $sans-serif-font-stack;
	font-size: 90%;

	line-height: 1.15;
	padding: .5em;
    }

    input[type='text'],
    input[type='email'],
    textarea {
	margin-top: .5em;
	width: 100%;
    }

    textarea {
	height: 8em;
    }

    input[type='submit'] {
	background-color: $button-background;
	border: 1px solid button-border;

	color: $button-text;
	cursor: pointer;

	font-family: $sans-serif-font-stack;
	font-size: 18px;

	margin: .5em 0;
	outline: 0;
	padding: .375em 1.25em;

    }

    input[type='submit']:hover,
    .button:hover {
	background-color: $button-text;
	color: $button-background;
    }

    // Direct float of page to right-aligned footer
    .buttons {
	padding: 0;
	text-align: right;
    }

}
